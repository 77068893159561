/* General Styles */
body {

  background-color: #ffffff;
  margin: 0;
  font-family: "Content", system-ui;
  line-height: 1.5;
  color: #333333;
  /* Default text color */
}

/* User Card Wrapper */
.user-card {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* User Info Card */
.user-info {
  max-width: 600px;
  width: 100%;
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

}

/* User Background Image */
.user-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  display: block;
}

/* User Information */
.user-details {
  padding: 20px;
  text-align: center;
  /* Center align text */
}

.user-name {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin: 0;
}

.user-idnumber,
.user-department,
.user-position,
.user-username {
  font-size: 16px;
  color: #666666;
  margin: 8px 0;
}

/* Buttons and Actions */
.user-actions {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 16px;
}

.view-profile {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.view-profile:hover {
  text-decoration: underline;
}

.contact-button {
  background-color: #28a745;
  color: #ffffff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #218838;
}
.image-profile {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  /* Circular shape */
  border: 4px solid white;
  /* White border */
  position: absolute;
  top: 180px;
  /* Distance from the top */
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center relative to the parent */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  /* Subtle shadow effect */
}
     /* Ensure A4 paper size */
     @page {
       size: A4;
       margin: -1mm;
     }
 
@media (max-width: 900px) {
  .image-profile {
      top: 31%;
      width: 160px;
      height: 160px;
    }
  .div-container {
    position: absolute;
    top: 45%;
    left: 25%;
    line-height: 1.1;
    font-size: 18px;
  }
}
@media (min-width: 900px) {
  .image-profile {
    top: 31%;
    width: 160px;
    height: 160px;
  }

  .div-container {
    position: absolute;
    top: 46%;
    left: 30%;
    line-height: 1.3;
    font-size: 16px;
  }
}
@media (max-width: 850px) {
  .image-profile {
      top: 31%;
      width: 140px;
      height: 140px;
    }
  .div-container {
    position: absolute;
    top: 45%;
    left: 30%;
    line-height: 1.2;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .image-profile {
      top: 32%;
      width: 120px;
      height: 120px;
    }
  .div-container {
    position: absolute;
    top: 45%;
    left: 30%;
    line-height: 1.2;
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .image-profile {
    top: 31%;
      width: 100px;
      height: 100px;}
  .div-container {
    position: absolute;
 
    top: 47%;
    left: 30%;
    line-height: 0.9;
    font-size: 11px;
    
  }
}

@media (max-width:  430px) {
  .image-profile {
    top: 31%;
    width: 80px;
    height: 80px;
  }

  .div-container {
    position: absolute;
    top: 49%;
    left: 30%;
    line-height: 0.8;
    font-size: 9px;
    font-family: "Content", system-ui;
  }
}
 